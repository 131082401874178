export default function Logo({ regular, white, className }) {
  let src;

  //   switch (resolvedTheme) {
  //     case "light":
  //       src = "https://ik.imagekit.io/noah/Logo%20With%20Typeface.png";
  //       break;
  //     case "dark":
  //       src = "https://ik.imagekit.io/noah/Logo%20With%20Typeface%20White.png";
  //       break;
  //     default:
  //       src = "https://ik.imagekit.io/noah/Logo%20With%20Typeface.png";
  //       break;
  //   }
  return (
    <>
      {regular && (
        <img
          src='https://ik.imagekit.io/noah/Logo%20With%20Typeface.png'
          alt='Blue Paspot logo'
          width={112}
          height={32}
          className={className}
        />
      )}
      {white && (
        <img
          src='https://ik.imagekit.io/noah/Logo%20With%20Typeface%20White.png?updatedAt=1715884409279'
          alt='White alternate logo of Paspot'
          width={128}
          height={32}
          className={className}
        />
      )}
    </>
  );
}
