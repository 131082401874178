import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import AppForm from "./AppForm";
import AppFormField from "./AppFormFied";
import { FcGoogle } from "react-icons/fc";
import AuthHeader from "./authHeader";
import { BiArrowBack } from "react-icons/bi";
import auth from "../../api/auth";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name field is required"),
  email: yup
    .string()
    .email("Invalid email")
    .required("Email field is required"),
  phoneNumber: yup.string().required("Phone Number field is required"),
  password: yup.string().required("Password field is required"),
});

export default function Signupform({
  onClose,
  noAuthHeader = false,
  fromBuyTicket,
  showAuthHeader = true,
  handleBackClick,
  onLoginSuccess,
}) {
  const [loading, setLoading] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);

  const handleOnSubmit = async ({ name, email, password, phoneNumber }) => {
    window.localStorage.clear();
    setLoading(true);
    if (fromBuyTicket) {
      const response = await auth.signUp(name, email, phoneNumber, password);
      if (
        response.status === 400 &&
        response.data.message === "Email address is already in use"
      ) {
        return setLoading(false), setEmailInUse(true);
      }

      if (!response.ok) return console.log(response), setLoading(false);
      const result = await auth.login(email, password);
      if (!result.ok) return console.log(result), setLoading(false);

      window.localStorage.setItem("token", result.data.accessToken);
      window.localStorage.setItem("user", result.data.user._id);
      window.localStorage.setItem("email", result.data.user.email);

      window.localStorage.setItem("authType", "planner");

      setLoading(false);
      window.location.reload();
    } else {
      window.localStorage.setItem(
        "user",
        JSON.stringify({ name, email, password, phoneNumber })
      );
      window.location.replace("/billinginfo");
    }
  };

  return (
    <AppForm
      initialValues={{ name: "", email: "", password: "", phoneNumber: "" }}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {emailInUse === true && (
        <div className='text-left p-4 bg-red-50 text-red-500 flex items-center rounded-sm'>
          This email is already in use, try using another email or login with
          the right password.
        </div>
      )}
      {showAuthHeader ? (
        " "
      ) : (
        <div className='flex flex-col gap-4'>
          <div className='flex items-center justify-between'>
            <button
              onClick={handleBackClick}
              className='flex items-center gap-2 uppercase text-accent font-medium'
            >
              <BiArrowBack size={20} /> Back
            </button>
          </div>

          <h2 className='font-bold text-2xl uppercase text-left'>
            Create an account
          </h2>
        </div>
      )}
      {noAuthHeader ? (
        ""
      ) : (
        <AuthHeader title='CREATE AN ACCOUNT' close={onClose} />
      )}

      {loading ? (
        <span className='loading loading-spinner text-primary h-44 mx-auto' />
      ) : (
        <>
          <AppFormField name='name' title='Full Name' type='name' />

          <AppFormField name='email' title='Email' type='email' />

          <AppFormField name='phoneNumber' title='Phone Number' />

          <AppFormField
            name='password'
            autoComplete='current-password'
            title='Password'
            type='password'
          />
          <button
            className='btn btn-primary font-medium text-white'
            type='submit'
          >
            Sign Up
          </button>
          {/* <p>Or</p>
          <button className='btn btn-outline btn-block border-neutral-content font-medium'>
            <FcGoogle /> Sign in with Google
          </button> */}
        </>
      )}
    </AppForm>
  );
}
