import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import { PaystackButton } from "react-paystack";
import CheckoutSummary from "./checkoutSummary";

const SelectTickets = ({
  tickets,
  handleOnChange,
  finalPrice,
  buttonLoading,
  handleOnTicketFree,
  componentProps,
  handleBackClick,
  toggleMenu,
  inner,
  resetFinalPrice,
}) => {
  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState({});

  const handleTicketChange = (e, ticketName, price) => {
    const quantity = parseInt(e.target.value, 10);
    setSelectedTickets({
      ...selectedTickets,
      [ticketName]: { number: quantity, price: price },
    });
    handleOnChange(e, ticketName); // Call the original onChange handler if needed
  };

  const handleProceedToCheckout = () => {
    if (Object.keys(selectedTickets).length === 0) {
      alert("Please select at least one ticket.");
      return;
    }
    setShowCheckout(true);
  };

  const handleBackToTickets = () => {
    setShowCheckout(false);
    setSelectedTickets({}); // Clear selected tickets entirely
    console.log(setSelectedTickets({}));
    resetFinalPrice();
  };

  if (showCheckout) {
    return (
      <CheckoutSummary
        ticketCart={selectedTickets}
        finalPrice={finalPrice}
        paspotCut={componentProps.paspotCut}
        componentProps={componentProps}
        handleBackToTickets={handleBackToTickets}
      />
    );
  }
  return (
    <div className='text-left flex flex-col gap-6'>
      {inner ? (
        <div className='flex items-center justify-between'>
          <button onClick={handleBackClick} className='btn btn-sm btn-circle'>
            <BiArrowBack size={20} />
          </button>
          <button onClick={toggleMenu} className='btn btn-sm btn-circle'>
            <GrFormClose size={20} />
          </button>
        </div>
      ) : (
        ""
      )}
      <h1 className='font-bold text-xl md:text-2xl uppercase'>
        Select a ticket
      </h1>
      <div className='flex justify-evenly flex-col gap-4 text-center'>
        <ul className='space-y-8'>
          {tickets.map(({ oneTicketClass }) => (
            <li
              key={oneTicketClass._id}
              className='flex items-center justify-between text-left'
            >
              <div>
                <span className='font-semibold'>
                  {oneTicketClass.ticketName}
                </span>
                <p className='text-sm text-primary'>₦{oneTicketClass.price}</p>
              </div>

              <select
                name='ticketquantity'
                value={
                  selectedTickets[oneTicketClass.ticketName]?.number || " "
                }
                onChange={(e) =>
                  handleTicketChange(
                    e,
                    oneTicketClass.ticketName,
                    oneTicketClass.price
                  )
                }
                id={oneTicketClass._id}
                className='p-1 border border-black rounded-lg'
              >
                <option value='0'>0</option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
              </select>
            </li>
          ))}
        </ul>
        {/* <button onClick={handleBackToTickets} className='btn'>
          Reset
        </button> */}
        {finalPrice === 0 ? (
          <button
            className={`btn btn-primary font-medium text-white ${
              buttonLoading ? "opacity-50 cursor-not-allowed rounded-full" : ""
            }`}
            onClick={() => {
              if (Object.keys(selectedTickets).length === 0) {
                alert("Please select at least one ticket.");
                return;
              }
              handleOnTicketFree();
            }}
          >
            {buttonLoading ? (
              <span className='loading loading-spinner text-white' />
            ) : (
              "Secure Tickets"
            )}
          </button>
        ) : (
          <button
            className='btn btn-primary'
            onClick={handleProceedToCheckout}
            disabled={buttonLoading}
          >
            Proceed to Checkout - ₦{finalPrice}
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectTickets;
