export default function EventsDetailSkeleton() {
  return (
    <>
      <div className='max-h-screen space-y-6 animate-pulse px-4 pt-36 ease-in-out duration-700 '>
        <div className='fixed top-0 bg-white border-b-gray-300 px-4 py-8 left-0 w-full space-y-8'>
          <div class='bg-slate-200 rounded h-8 w-24'></div>

          <div className='flex gap-4 overflow-x-auto flex-nowrap items-center'>
            <div class='bg-slate-200 rounded h-8 w-28'></div>
            <div class='bg-slate-200 rounded h-8 w-28'></div>
            <div class='bg-slate-200 rounded h-8 w-28'></div>
            <div class='bg-slate-200 rounded h-8 w-28'></div>
            <div class='bg-slate-200 rounded h-8 w-28'></div>
            <div class='bg-slate-200 rounded h-8 w-28'></div>
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <div class='bg-slate-200 rounded h-8 w-24'></div>
          <div class='bg-slate-200 rounded h-8 w-16'></div>
        </div>

        <div class='bg-slate-200 rounded-lg h-72 w-full'></div>

        <div class='bg-slate-200 rounded-lg h-10 w-full'></div>

        <div class='bg-slate-200 rounded-lg h-72 w-full'></div>
      </div>
    </>
  );
}
