import React, { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { BuyTicketSkeleton, Footer, Loader, Navbar1 } from "./components";
import PasswordRest from "./routes/passwordRest.jsx";

const Buyticket = lazy(() => import("./routes/users/buyticket.jsx"));
const Discover = lazy(() => import("./routes/users/Discover.jsx"));
const Dashboard = lazy(() => import("./routes/planners/dashboard.jsx"));
const Landing = lazy(() => import("./routes/landing.jsx"));
const Login = lazy(() => import("./routes/planners/login.jsx"));
const Signup = lazy(() => import("./routes/planners/signup.jsx"));
const Account = lazy(() => import("./routes/planners/account.jsx"));
const Coordinator = lazy(() => import("./routes/planners/coordinator.jsx"));
const Coordinatordetails = lazy(() =>
  import("./routes/planners/coordinatordetails.jsx")
);
const Createcoordinators = lazy(() =>
  import("./routes/planners/createcoordinators.jsx")
);
const Createevent = lazy(() => import("./routes/planners/createevent.jsx"));

const Createevent3 = lazy(() => import("./routes/planners/createevent3.jsx"));
const Createticket = lazy(() => import("./routes/planners/createticket.jsx"));
const Billinginfo = lazy(() => import("./routes/planners/billinginfo.jsx"));
const Uploadimg = lazy(() => import("./routes/planners/uploadimg.jsx"));
const Editevent = lazy(() => import("./routes/planners/editevent.jsx"));
const Successful = lazy(() => import("./routes/users/successful.jsx"));
const Priceconfirm = lazy(() => import("./routes/users/priceconfirm.jsx"));
const Passcode = lazy(() => import("./routes/planners/passcode.jsx"));
const Socials = lazy(() => import("./routes/planners/socials.jsx"));
const Scan = lazy(() => import("./routes/planners/Scan.jsx"));
const Help = lazy(() => import("./routes/help.jsx"));
const ComingSoon = lazy(() => import("./routes/comingSoon.jsx"));
const Physicalticket = lazy(() => import("./routes/physicalticket.jsx"));
const EventSupport = lazy(() => import("./routes/eventSupport.jsx"));
const Insight = lazy(() => import("./routes/insight.jsx"));
const Marketing = lazy(() => import("./routes/marketing.jsx"));
const Tickets = lazy(() => import("./routes/users/tickets.jsx"));
const AppAccount = lazy(() => import("./routes/users/appAccount.jsx"));
const UserSignIn = lazy(() => import("./routes/users/userSignIn.jsx"));
const Error = lazy(() => import("./routes/error.jsx"));
const Services = lazy(() => import("./routes/services.jsx"));
const Events = lazy(() => import("./routes/planners/Events"));
const AllTickets = lazy(() => import("./routes/planners/AllTickets"));
const EditProfile = lazy(() => import("./routes/planners/editProfile"));
const EditBankInfo = lazy(() => import("./routes/planners/editBankInfo"));
const ChangePassword = lazy(() => import("./routes/changePassword"));
const TicketSuccess = lazy(() => import("./routes/planners/ticketSuccess"));
const EventTest = lazy(() => import("./routes/planners/eventTest"));
const Buyticket2 = lazy(() => import("./routes/users/buyticket2.jsx"));
//
const UserSelect = lazy(() => import("./routes/userSelect.jsx"));
const About = lazy(() => import("./routes/About.jsx"));
//
const PeusdoSuccessful = lazy(() =>
  import("./routes/users/peusdoSuccessful.jsx")
);
const EventPlanners = lazy(() => import("./routes/EventPlanners.jsx"));
const Venues = lazy(() => import("./routes/Venues.jsx"));
const Urgent2k = lazy(() => import("./routes/Urgent2k.jsx"));
const EditTicket = lazy(() => import("./routes/planners/editTicket.jsx"));

function App() {
  document.title = "Paspot Admin";
  return (
    <>
      <Router>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Events />} />
            {/* <Route path='/home' element={<HomePage />} /> */}
            <Route path="/login" element={<Login />} />
            <Route path="/passwordReset" element={<PasswordRest />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/account" element={<Account />} />
            <Route path="/coordinator" element={<Coordinator />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/billinginfo" element={<Billinginfo />} />
            <Route path="/createevent" element={<Createevent />} />
            <Route path="/events" element={<Events />} />
            <Route path="/uploadimg" element={<Uploadimg />} />
            <Route path="/createevent3" element={<Createevent3 />} />
            <Route path="/createticket" element={<Createticket />} />
            <Route path="/editevent" element={<Editevent />} />
            <Route path="/buyticket/:linkName" element={<Buyticket />} />
            <Route path="/buyticket" element={<Buyticket />} />
            <Route path="/successful" element={<Successful />} />
            <Route path="/priceconfirm/:linkName" element={<Priceconfirm />} />
            <Route path="/passcode" element={<Passcode />} />
            <Route
              path="/createcoordinators"
              element={<Createcoordinators />}
            />
            <Route path="/socials" element={<Socials />} />
            <Route path="/scan" element={<Scan />} />
            <Route path="/help" element={<Help />} />
            <Route path="/physicalticket" element={<Physicalticket />} />
            <Route
              path="/coordinatordetails"
              element={<Coordinatordetails />}
            />
            <Route path="/services" element={<Services />} />
            <Route path="/eventSupport" element={<EventSupport />} />
            <Route path="/insight" element={<Insight />} />
            <Route path="/marketing" element={<Marketing />} />
            <Route path="/liveevents" element={<Discover />} />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/appAccount" element={<AppAccount />} />
            <Route path="/comingSoon" element={<ComingSoon />} />
            <Route path="/events" element={<Events />} />
            <Route path="/AllTickets" element={<AllTickets />} />
            <Route path="/EditProfile" element={<EditProfile />} />
            <Route path="/EditBankInfo" element={<EditBankInfo />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/EventTest" element={<EventTest />} />
            <Route path="/TicketSuccess" element={<TicketSuccess />} />
            <Route path="/buyticket2/:linkName" element={<Buyticket2 />} />
            <Route path="/peusdosuccessful" element={<PeusdoSuccessful />} />
            <Route path="/userselect" element={<UserSelect />} />
            <Route path="/about" element={<About />} />
            <Route path="/eventplanners" element={<EventPlanners />} />
            <Route path="/venues" element={<Venues />} />
            <Route path="/urgent2k" element={<Urgent2k />} />
            <Route path="/editticket" element={<EditTicket />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
//
