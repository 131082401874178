import React from "react";
import { Socialicons } from ".";

export default function DetailSection({
  description,
  website,
  instagram,
  facebook,
  twitter,
  mapName,
}) {
  return (
    <>
      <div className='w-full space-y-10 md:space-y-20 mb-24'>
        <div>
          <h1 className='text-xl md:text-2xl font-bold mb-6'>
            About this Event
          </h1>
          {/* SHOW NOTHING IF NOT PROVIDED */}
          <p>{description}</p>
        </div>

        <div className='grid grid-cols-2 items-center w-full gap-4 md:gap-6'>
          <a
            target='blank'
            href={`https://www.google.com/maps/dir/?api=1&origin=current+location&destination=${mapName}`}
            className='btn btn-outline normal-case'
          >
            Get Directions
          </a>
          {/* <button className='btn btn-outline normal-case'>Book Ride</button> */}
        </div>

        <div>
          <h1 className='text-xl md:text-2xl font-bold mb-6'>Socials</h1>
          <div className='flex items-center justify-start mt-4 md:mt-10 gap-10'>
            <Socialicons icon='website' link={website} size='20' />
            <Socialicons icon='instagram' link={instagram} size='20' />
            <Socialicons icon='facebook' link={facebook} size='20' />
            <Socialicons icon='twitter' link={twitter} size='20' />
          </div>
        </div>

        <div className='flex justify-center md:justify-start'>
          <img
            src='https://ik.imagekit.io/noah/paystack-badge-f9d2217.png?updatedAt=1704593477921'
            alt='logo of payment provider paystack'
            className='w-96 h-auto'
          />
        </div>

        <a
          href='https://wa.me/+2347081515331'
          target='blank'
          className='btn btn-block btn-outline normal-case'
        >
          Report a problem
        </a>
      </div>
    </>
  );
}
