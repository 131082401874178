import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import auth from "../../api/auth";
import Loader from "../loader";
import AppForm from "./AppForm";
import AppFormField from "./AppFormFied";
import { FcGoogle } from "react-icons/fc";
import AuthHeader from "./authHeader";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .required("Email field is required"),
  password: yup.string().required("Password field is required"),
});

const validationSchemaReset = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .required("Email field is required"),
});

function Login({ title = "Login", onClose, redirect }) {
  const [loading, setLoading] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [showReset, setShowReset] = useState(false);
  //
  // const [resetLink, setResetLink] = useState(false);

  const navigate = useNavigate();

  const handleForgotPasswordClick = () => {
    setShowReset(true);
  };
  const handleBackToLoginClick = () => {
    setShowReset(false);
  };

  const handleOnSubmitPlanner = async ({ email, password }) => {
    window.localStorage.clear();
    setLoading(true);
    setEmailInvalid(false);
    setPasswordInvalid(false);
    const response = await auth.login(email, password);
    if (
      response.status === 400 &&
      response.data.message === "Incorrect Password Provided"
    ) {
      // eslint-disable-next-line
      return setLoading(false), setPasswordInvalid(true);
    }
    if (
      response.status === 400 &&
      response.data.message === "Invalid Email Address"
      // eslint-disable-next-line
    ) {
      // eslint-disable-next-line
      return setLoading(false), setEmailInvalid(true);
    }
    // eslint-disable-next-line
    if (!response.ok) return console.log(response), setLoading(false);
    window.localStorage.setItem("token", response.data.accessToken);
    window.localStorage.setItem("user", response.data.user._id);
    window.localStorage.setItem("email", response.data.user.email);
    window.localStorage.setItem("name", response.data.user.name);
    window.localStorage.setItem("phoneNumber", response.data.user.phoneNumber);
    window.localStorage.setItem("authType", "planner");

    if (redirect) {
      navigate(redirect);
    } else {
      window.location.reload();
    }

    setLoading(false);
  };

  const handleResetPassword = async ({ email }) => {
    console.log("reset button clicked", email);
    const response = await auth.requestPasswordReset(email);
    console.log("reset function called", response);
    alert("Reset link Sent to your inbox");
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="w-full md:max-w-xl">
      {showReset ? ( // RESET CONTENT
        <AppForm
          initialValues={{ email: "" }}
          validationSchema={validationSchemaReset}
          onSubmit={handleResetPassword}
        >
          {emailInvalid === true && (
            <div className="text-left p-4 bg-red-50 text-red-500 flex items-center rounded-sm">
              Incorrect email address, try to create a new account with this
              email.
            </div>
          )}
          <>
            <AuthHeader
              inner
              title="Reset password"
              subtitle="Drop your email and we'll send you a link to create a new password. Maybe you go remember this wan next time hmm?"
              back={handleBackToLoginClick}
            />
            <AppFormField name="email" title="Email" type="email" />
            <button
              type="submit"
              className="btn btn-primary font-medium text-white w-full"
              // onClick={handleResetPassword}
            >
              SEND LINK
            </button>
          </>
        </AppForm>
      ) : (
        // LOGIN CONTENT
        <AppForm
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmitPlanner}
        >
          {emailInvalid === true && (
            <div className="text-left p-4 bg-red-50 text-red-500 flex items-center rounded-sm">
              Incorrect email address, try to create a new account with this
              email.
            </div>
          )}
          {passwordInvalid ? (
            <div className="text-left p-4 bg-red-50 text-red-500 flex items-center rounded-sm">
              Your password is incorrect boss.
            </div>
          ) : null}
          <>
            <AuthHeader title="Login to Paspot" close={onClose} />
            <AppFormField name="email" title="Email" type="email" />
            <AppFormField
              name="password"
              autoComplete="current-password"
              title="Password"
              type="password"
            />
            {/* Forgot Password Section */}
            <button
              className="text-primary text-left"
              onClick={handleForgotPasswordClick}
            >
              Forgot Password?
            </button>
            {/* Section End */}
            <div className="space-y-2">
              <button
                type="submit"
                className="btn btn-primary btn-block text-white font-medium"
              >
                Login
              </button>
              {/* <p>Or</p>
              <button className='btn btn-outline btn-block border-neutral-content font-medium'>
                <FcGoogle /> Sign in with Google
              </button> */}
            </div>
          </>
        </AppForm>
      )}
      <hr />
    </div>
  );
}

export default Login;
