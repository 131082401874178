export default function DiscoverPageSkeleton() {
  return (
    <>
      <div className='max-h-screen space-y-6 animate-pulse px-4 pt-20 ease-in-out duration-700 '>
        <div className='fixed top-0 bg-white border-b-gray-300 p-4 left-0 w-full flex items-center justify-between'>
          <div class='bg-slate-200 rounded h-4 w-20'></div>
          <div class='bg-slate-200 rounded h-4 w-12'></div>
        </div>

        <div class='bg-slate-200 rounded-lg h-10 w-full'></div>

        <div class='bg-slate-200 rounded-lg h-96 w-full'></div>

        <div className='space-y-4'>
          <div class='bg-slate-200 rounded h-4 w-36'></div>
          <div class='bg-slate-200 rounded h-4 w-16'></div>
        </div>
      </div>
    </>
  );
}
