import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const FormHeader = ({ title, child }) => {
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div
      className={`fixed top-0 left-0 w-full  pt-2 z-10 bg-white border-b space-y-4 ${
        navbar ? "border-gray-100" : "border-none"
      } `}
    >
      <button onClick={goBack} className='btn btn-ghost normal-case'>
        <BiArrowBack size={20} /> <p className='font-bold text-xl'>{title}</p>
      </button>
      {child}
    </div>
  );
};

export default FormHeader;
