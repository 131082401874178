import { Link } from "react-router-dom";
import ticketsApi from "../api/tickets";
import { TicketList } from "../components";
import { useState, useEffect } from "react";

export default function TicketCrud({ id }) {
  // declare states
  const [ticketClasses, setTicketClasses] = useState([]);
  // fetch ticketClasses with useEffect hook
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ticketsApi.getTicketsTypes(id);
        if (!response.ok) return console.log(response);
        setTicketClasses(response.data);
        // console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  // parse the data from response
  // set states

  // implement loading state with skeleton

  const itemStyle = "font-medium p-3 md:p-5";

  return (
    <div className='space-y-6'>
      <div className='flex items-center justify-between'>
        <p className='font-bold text-2xl'>Tickets</p>

        <Link
          to={"/createticket?" + id}
          className='btn btn-outline normal-case'
        >
          Add Ticket
        </Link>
      </div>

      <div className='border border-black rounded-lg mt-12 overflow-hidden'>
        <table className='table-auto w-full text-left text-white'>
          <thead className='bg-black'>
            <tr>
              <th className={itemStyle}>Name</th>
              <th className={itemStyle}>Quantity</th>
              <th className={itemStyle}>Price</th>
              <th className={itemStyle}>Action</th>
            </tr>
          </thead>
          <tbody className='w-full'>
            {ticketClasses.map((ticketClass) => (
              <TicketList props={ticketClass} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
