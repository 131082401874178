import { useState } from "react";

export default function EventAbout({ content }) {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  return (
    <>
      <p
        className={`overflow-hidden overflow-ellipsis ${
          showFullContent ? "line-clamp-none" : "line-clamp-3"
        } `}
      >
        {content}
      </p>
      {!showFullContent && (
        <button onClick={toggleContent} className='text-primary uppercase'>
          Read more
        </button>
      )}
    </>
  );
}
