import { RiArrowLeftLine, RiCloseFill } from "@remixicon/react";

export default function AuthHeader({
  title,
  subtitle,
  back,
  close,
  inner,
  imageContent,
}) {
  return (
    <>
      {inner ? (
        <>
          {/* INNER HEADER */}
          <button
            onClick={back}
            className='flex items-center gap-2 text-primary'
          >
            <RiArrowLeftLine size={16} /> Back to login
          </button>
          <h1 className='font-bold uppercase text-xl md:text-2xl text-left'>
            {title}
          </h1>
          <span className='text-left'>{subtitle}</span>
        </>
      ) : (
        <div className='flex flex-col gap-4 w-full'>
          {/* OUTER HEADER */}

          {imageContent}
          <h1 className='font-bold uppercase text-xl md:text-2xl text-left'>
            {title}
          </h1>
          <span>{subtitle}</span>
        </div>
      )}
    </>
  );
}
