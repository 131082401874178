// EventItem.js
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";

export default function EventComponent({ event }) {
  const navigate = useNavigate();
  const { image, eventName, linkName, dateAndTime, location } = event;

  const formatDate = (dateString) => {
    let formattedDate;
    if (dateString === "Undisclosed") {
      formattedDate = dateString;
    } else {
      const [year, month, day] = dateString.split("-");

      const date = new Date(year, month - 1, day);

      // Format the day with appropriate suffix
      let dayWithSuffix = day;
      switch (day % 10) {
        case 1:
          dayWithSuffix += "st";
          break;
        case 2:
          dayWithSuffix += "nd";
          break;
        case 3:
          dayWithSuffix += "rd";
          break;
        default:
          dayWithSuffix += "th";
          break;
      }

      // Format the month name
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      const monthName = monthNames[date.getMonth()];

      formattedDate = `${dayWithSuffix} ${monthName}, ${year}`;
    }

    return formattedDate;
  };

  // const handleClick = () => {
  //   navigate(`/buyticket/${linkName}`);
  // };

  return (
    <Link reloadDocument to={`/buyticket/${linkName}`} className='space-y-4'>
      <img
        src={image}
        alt='event image'
        className='w-full h-52 object-cover rounded-md'
      />
      <div className='flex flex-col gap-2 text-left'>
        <p className='font-semibold normal-case'>{eventName}</p>
        <div className='flex items-center gap-2 text-sm text-neutral-content'>
          <span className='truncate'>{formatDate(dateAndTime.startDate)}</span>{" "}
          |<span className='truncate'>{location}</span>
        </div>
      </div>
    </Link>
  );
}
