import React, { useState } from "react";
import "react-select/dist/react-select.css";
import VirtualizedSelect from "react-virtualized-select";
import "react-virtualized-select/styles.css";
import "react-virtualized/styles.css";
import * as yup from "yup";
import auth from "../../api/auth";
import bankList from "../../assets/utils/banks";
import Loader from "../loader";
import AppForm from "./AppForm";
import AppFormField from "./AppFormFied";

const validationSchema = yup.object().shape({
  accountName: yup.string().required("Account Name field is required"),
  accountNumber: yup.string().required("Account Number field is required"),
});

function Bankform() {
  const [loading, setLoading] = useState(false);
  const [bankName, setBankName] = useState("");
  const [emailInUse, setEmailInUse] = useState(false);

  const banks = bankList.map((bank) => ({ label: bank.name, value: bank.id }));

  const handleOnSubmit = async ({ accountName, accountNumber }) => {
    setLoading(true);
    const user = JSON.parse(window.localStorage.getItem("user"));
    const bankDetails = {
      accountName: accountName,
      accountNumber: accountNumber,
      bankName: bankName.label,
    };
    const response = await auth.signUp(
      user.name,
      user.email,
      user.phoneNumber,
      user.password,
      bankDetails
    );
    if (
      response.status === 400 &&
      response.data.message === "Email address is already in use"
    ) {
      return setLoading(false), setEmailInUse(true);
    }

    // eslint-disable-next-line
    if (!response.ok) return console.log(response), setLoading(false);
    // eslint-disable-next-line
    const result = await auth.login(user.email, user.password);
    // eslint-disable-next-line
    if (!result.ok) return console.log(result), setLoading(false);
    // eslint-disable-next-line
    window.localStorage.setItem("token", result.data.accessToken);
    window.localStorage.setItem("user", result.data.user._id);
    window.localStorage.setItem("email", result.data.user.email);
    window.localStorage.setItem("authType", "planner");
    //
    setLoading(false);
    window.location.reload();
    window.location.replace("/");
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='w-full'>
      <h2 className='font-bold uppercase text-2xl md:text-3xl mb-10'>
        Billing Info
      </h2>
      <AppForm
        initialValues={{ accountName: "", accountNumber: "" }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {emailInUse === true && (
          <div className='text-left p-4 bg-red-50 text-red-500 flex items-center rounded-sm'>
            This email is already in use, go back and try using another email or
            login with the right password.
          </div>
        )}
        <AppFormField name='accountName' type='name' title='Account Name' />
        <div className='w-full text-left'>
          <div>
            <label htmlFor='bankname'>Bank Name</label>
            <VirtualizedSelect
              clearable={false}
              options={banks}
              placeholder='  '
              onChange={(selectValue) => setBankName(selectValue)}
              value={bankName}
            />
          </div>
        </div>
        <AppFormField name='accountNumber' title='Account Number' />
        <button
          className='btn btn-primary font-medium text-white'
          type='submit'
        >
          Submit
        </button>
      </AppForm>
    </div>
  );
}

export default Bankform;
