import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { MdFilterList } from "react-icons/md";
import AppForm from "./forms/AppForm";
import AppFormField from "./forms/AppFormFied";
import { GrFormClose } from "react-icons/gr";
import payoutsApi from "../api/payouts";
import Modal from "./Modal";
import PayoutItem from "../components/payoutItem";
//
export default function Payout({ id }) {
  const [toggle, setToggle] = useState(false);
  const [successRequest, setSuccessRequest] = useState(false);
  const [unauthorizedModal, setUnauthorizedModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [havePayout, setHavePayout] = useState(false);

  const [payoutHistory, setPayoutHistory] = useState([]);
  const [payoutSum, setPayoutSum] = useState(0);

  const toggleFilter = () => {
    setToggle(!toggle);
  };

  const toggleSuccessRequest = () => {
    setSuccessRequest(!successRequest);
  };

  const toggleUnauthorizedModal = () => {
    setUnauthorizedModal(!unauthorizedModal);
  };

  const handleOnPressPayout = async () => {
    try {
      setLoading(true);
      const result = await payoutsApi.requestPayout(id);

      if (result.status === 201) {
        toggleSuccessRequest();

        window.location.reload();
      } else {
        toggleUnauthorizedModal();
      }

      console.log(result);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getPayoutHistory = async () => {
      // const queryString = window.location.href.split("?")[1];
      const response = await payoutsApi.getPayoutHistory(id);
      console.log('Response', response.data);
      if (!response.ok) return console.log(response);
      if (response.data.payouts[response.data.payouts.length - 1].fufilled === false) {
        setHavePayout(true);
      }
      setPayoutHistory(response.data.payouts.reverse());
      //
      setPayoutSum(response.data.payoutSum);
      // console.log('Response:', response.data);
    };
    getPayoutHistory();
  }, []);

  return (
    <>
      <div className='flex flex-col gap-8'>
        <div className='space-y-6'>
          <h1 className='font-bold text-xl md:text-2xl uppercase'>Payouts</h1>
          <div className='bg-secondary md:h-42 border border-black text-white w-full p-4 shadow-web md:shadow-web rounded-md'>
            <div className='space-y-4'>
              <div className='flex items-center justify-between'>
                <span className='text-s text-black'>Amount Paid</span>

                <span></span>
              </div>
              <h2 className='font-bold text-xl text-black text-left mb-4'>
                ₦{payoutSum}
              </h2>
              {!havePayout ? (
                <button
                  onClick={handleOnPressPayout}
                  className='btn btn-block btn-neutral text-white font-medium'
                  disabled={loading}
                >
                  {loading ? (
                    <span className='loading loading-spinner'></span>
                  ) : (
                    "Issue a Payout"
                  )}
                </button>
              ) : (
                <>
                  <button
                    className='btn btn-block btn-neutral text-white font-medium'
                    disabled
                  >
                    Issue Payout
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        {!havePayout ? (
          ""
        ) : (
          <span className='text-sm text-neutral-content text-center'>
            You can’t request for payout until the current one has been resolved
          </span>
        )}

        {/* {havePayout ? (
          ""
        ) : (
          <div className='p-4 border-black border rounded-md shadow-web space-y-4'>
            <div className='flex items-center justify-between'>
              <h1 className='font-semibold text-xl'>Track Payout</h1>
              <span className='badge badge-ghost'>ID: #24</span>
            </div>
            <ul className='steps steps-vertical text-left w-full'>
              <li className='step step-success'>Request Sent</li>
              <li className='step'>Request Confirmed</li>
              <li className='step'>Paid🎉</li>
            </ul>
            <button className='btn btn-outline btn-block'>
              Report an Issue
            </button>
          </div>
        )} */}
        <div className='space-y-6'>
          <h1 className='font-bold text-xl md:text-2xl uppercase'>History</h1>
          <div className='p-4 border-black border rounded-md shadow-web space-y-4'>
            <div className='flex items-center justify-between'>
              {/* <button
              onClick={toggleFilter}
              className="btn btn-outline normal-case"
            >
              <MdFilterList /> Filter
            </button> */}
            </div>

            {/* <div className="w-full px-3 rounded-lg border border-black flex items-center gap-2">
            <BiSearch size={24} />
            <input
              type="text"
              placeholder="Search for amount"
              className="w-full border-0 bg-transparent py-3 focus:outline-none"
            />
          </div> */}

            <ul>
              {payoutHistory.map((payout) => (
                <li>
                  <PayoutItem props={payout} key={payout._id} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* Successful Payout Request */}
      <Modal
        title='Success'
        state={successRequest}
        toggle={toggleSuccessRequest}
      >
        <div className='flex flex-col items-center gap-4'>
          <img
            src='https://ik.imagekit.io/noah/success.gif?updatedAt=1704589517755'
            alt='moshood dancing'
            className='w-40 h-40 rounded-lg'
          />

          <span>
            Your payout request has been made and is awating confirmation. You
            can track the progress of your payment in the tracking section and
            in any case of wahala, contact us by clicking the report button
          </span>

          <button
            onClick={toggleSuccessRequest}
            className='btn btn-block btn-primary normal-case'
          >
            Aiit Cool
          </button>
        </div>
      </Modal>
      {/* Unauthorized Payout */}
      <Modal
        title='Unauthorized Request'
        state={unauthorizedModal}
        toggle={toggleUnauthorizedModal}
      >
        <div className='flex flex-col items-center gap-4'>
          <img
            src='https://ik.imagekit.io/noah/why.jpg?updatedAt=1704589503107'
            alt='Why you dey do like this'
            className='w-40 h-40 rounded-lg'
          />

          <span>
            Be calming down, you can only make one withdrawal request at a time.
            But if this is urgent, contact us here👉🏿{" "}
            <a
              href='https://wa.me/+2347081515331'
              target='blank'
              className='text-primary underline'
            >
              here
            </a>
          </span>

          <button
            onClick={toggleUnauthorizedModal}
            className='btn btn-block btn-primary normal-case'
          >
            Close
          </button>
        </div>
      </Modal>

      {/* Filter Modal */}
      {/* <Modal title={"Filter"} state={toggle} toggle={toggleFilter}>
        <AppForm
          initialValues={{
            startDateTime: "",
            endDateTime: "",
          }}
          onSubmit={handleOnSubmit}
        >
          <div className="w-full text-left">
            <label className="label">
              <span className="label-text">Status</span>
            </label>
            <div className="w-full px-3 rounded-lg border border-black flex items-center justify-between">
              <select
                name="status"
                className="bg-transparent py-3 border-0 w-full focus:outline-none"
              >
                <option value="all">Show All</option>
                <option value="successful">Successful</option>
                <option value="pending">Pending</option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 items-center gap-4">
            <AppFormField
              name="startDateTime"
              type="datetime-local"
              title="Start Date"
            />
            <AppFormField
              name="endDateTime"
              type="datetime-local"
              title="End Date"
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block normal-case"
          >
            Submit
          </button>
        </AppForm>
      </Modal> */}
    </>
  );
}
