import React from "react";
import "../index.css";

function Loader() {
  return (
    <div className='w-full h-36 bg-base-100 flex justify-center items-center'>
      {/* <img src='/Construct.svg' alt='logo' className='w-8 md:w-12 h-auto' /> */}
      <span className='loading loading-spinner loading-primary' />
    </div>
  );
}

export default Loader;
