import React from "react";

function Verified({ eventName, ticketClass, id }) {
  return (
    <div className='flex flex-col px-4 gap-6 items-center justify-center h-screen text-white bg-[#468a54]'>
      <h2 className='font-bold text-2xl'>Verified</h2>
      <img
        src='https://ik.imagekit.io/noah/giphy.gif?updatedAt=1704591468533'
        alt='gif'
        className='md:w-96 w-full h-auto'
      />
      <div className='text-center space-y-4'>
        <h2>{eventName}</h2>
        <p className='bg-[#00000066] p-4 rounded-full font-bold'>
          {ticketClass}
        </p>
      </div>

      <button
        onClick={() => window.location.reload()}
        className='btn btn-block bg-white'
      >
        Go back
      </button>
    </div>
  );
}

export default Verified;
