import { Link } from "react-router-dom";

export default function Section({
  image,
  left,
  title,
  content,
  href,
  cta,
  sideThing,
  alt,
}) {
  return (
    <section className='mx-auto my-0 max-w-7xl px-4 md:px-0'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-12 pl-4 items-center'>
        <div className={`relative h-fit ${left ? "" : "md:order-1"}`}>
          <p
            style={{
              position: "absolute",
              transform: "rotate(-90deg) translateX(-100%)",
              transformOrigin: "0px",
              zIndex: -2,
            }}
            className={`text-4xl md:text-6xl top-[20%] md:top-[30%] ${
              left ? "left-0" : "md:hidden"
            }`}
          >
            {sideThing}
          </p>
          <img src={image} alt={alt} width={687} height={953} />
        </div>
        <div className='flex flex-col gap-4 justify-end'>
          <h1 className='font-black text-2xl md:text-5xl uppercase'>{title}</h1>
          <div className='max-w-xl'>
            <p>{content}</p>
          </div>
          <Link
            to={href}
            className='btn btn-primary font-medium text-white w-full md:w-fit'
          >
            {cta}
          </Link>
        </div>
      </div>
    </section>
  );
}
