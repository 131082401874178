import React, { Suspense, lazy, useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { BiChevronDown, BiChevronRight, BiSearch } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import { HiMenuAlt4 } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import eventsApi from "../../api/events";
import { FaUserCircle } from "react-icons/fa";
const EventItem = lazy(() => import("../EventItem"));

//
function Navbar3({ dark }) {
  const [navbar, setNavbar] = useState(false);
  const [events, setEvents] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [showChild, setShowChild] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    const getEvents = async () => {
      const result = await eventsApi.getAllEvents();
      if (!result.ok) return console.log(result);
      const eventsData = result.data.map((element) => ({
        ...element,
        link: "/buyticket/" + element.linkName,
      }));
      setEvents(eventsData);
      setFilteredEvents(eventsData);
    };
    getEvents();
  }, []);

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const navigate = useNavigate();

  window.addEventListener("scroll", changeBackground);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered = events.filter(
      (event) =>
        event.eventName.toLowerCase().includes(query.toLowerCase()) ||
        event.description.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredEvents([...filtered]); // Create a new array by spreading filtered
  };

  const handleOnPressLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      window.localStorage.clear();
      window.location.replace("/");
    }
  };

  const toggleNav = () => {
    setToggle(!toggle);
  };
  const toggleBar = () => {
    setToggleSearch(!toggleSearch);
  };
  const toggleChild = () => {
    setShowChild(!showChild);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const linkStyle = "hover:text-primary hidden md:flex";
  return (
    <div className='relative'>
      {/* USER NAVBAR */}
      <nav
        className={`fixed z-20 w-full top-0 left-0 
        ${
          navbar
            ? " bg-white border-b border-gray-100"
            : `bg-transparent border-white/10 border-b`
        }`}
      >
        <div className='py-3 px-4 md:px-0 flex items-center justify-between gap-8 md:gap-12 max-w-7xl mx-auto'>
          {dark ? (
            <a href='/'>
              <img
                src='https://ik.imagekit.io/noah/paspotblue.png?updatedAt=1709412003987'
                alt='logo'
                className='md:w-24 w-20 h-6 md:block hidden'
              />
              <img
                src='https://ik.imagekit.io/noah/blue.png?updatedAt=1710770153403'
                alt='logo'
                className='w-6 h-auto block md:hidden'
              />
            </a>
          ) : (
            <div onClick={() => navigate("/")}>
              {navbar ? (
                <a href='/'>
                  <img
                    src='https://ik.imagekit.io/noah/paspotblue.png?updatedAt=1709412003987'
                    alt='logo'
                    className='md:w-24 w-20 h-6 md:block hidden'
                  />
                  <img
                    src='https://ik.imagekit.io/noah/blue.png?updatedAt=1710770153403'
                    alt='logo'
                    className='w-6 h-auto block md:hidden'
                  />
                </a>
              ) : (
                <a href='/'>
                  <img
                    src='https://ik.imagekit.io/noah/paspot.png?updatedAt=1704589511955'
                    alt='logo'
                    className='md:w-24 w-20 h-auto md:block hidden'
                  />
                  <img
                    src='https://ik.imagekit.io/noah/white.png?updatedAt=1710777879341'
                    alt='logo'
                    className='w-6 h-auto block md:hidden'
                  />
                </a>
              )}
            </div>
          )}

          <div className='dropdown w-1/2 hidden md:block'>
            <div
              tabIndex={0}
              role='button'
              className={`hidden md:flex items-center gap-2 px-4 w-full ${
                dark
                  ? "bg-gray-100"
                  : ` ${navbar ? "bg-gray-100" : " bg-white/40 text-white"}`
              } rounded-full`}
            >
              <BiSearch size={20} />
              <input
                type='text'
                placeholder='Search for event name'
                className={`w-full border-0 bg-transparent py-3 text-sm focus:outline-none ${
                  dark
                    ? "placeholder-black"
                    : `${navbar ? "placeholder-black" : "placeholder-slate-50"}`
                }`}
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <ul
              tabIndex={0}
              className='dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-full mt-4'
            >
              <li>
                <Suspense>
                  {filteredEvents ? (
                    filteredEvents
                      .slice(0, 3)
                      .map((event) => <EventItem event={event} />)
                  ) : (
                    <span className='text-primary loading loading-spinner'></span>
                  )}
                </Suspense>
              </li>
            </ul>
          </div>

          <div
            onClick={toggleBar}
            className={`md:hidden flex items-center gap-2 px-4 w-full ${
              dark
                ? "bg-gray-100"
                : ` ${navbar ? "bg-gray-100" : " bg-white/40 text-white"}`
            } rounded-full`}
          >
            <BiSearch size={20} />
            <input
              type='text'
              placeholder='Search for event name'
              className={`w-full border-0 bg-transparent py-3 text-sm focus:outline-none ${
                dark
                  ? "placeholder-black"
                  : `${navbar ? "placeholder-black" : "placeholder-slate-50"}`
              }`}
            />
          </div>

          <ul
            className={`flex items-center gap-8 text-sm ${
              navbar ? " text-black" : ` ${dark ? "text-black" : "text-white"} `
            }`}
          >
            <Link reloadDocument to='/' className={linkStyle}>
              Home
            </Link>
            <Link reloadDocument to='/discover' className={linkStyle}>
              Browse events
            </Link>

            <div
              onClick={toggleChild}
              className='hover:text-primary cursor-pointer hidden md:flex items-center'
            >
              Work with us <BiChevronDown size={20} />
            </div>

            <details className='dropdown hidden md:flex'>
              <summary className='m-1 btn btn-circle btn-ghost'>
                <FaUserCircle size={28} />
              </summary>
              <ul className='p-2 text-black shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52 translate-x-minus mr-12'>
                <li>
                  <Link reloadDocument to='/appaccount'>
                    Your account
                  </Link>
                </li>

                <li>
                  <Link reloadDocument to='/tickets'>
                    Your tickets
                  </Link>
                </li>
                <li>
                  <Link reloadDocument to='/help'>
                    Help center
                  </Link>
                </li>
                <li>
                  <Link reloadDocument onClick={handleOnPressLogout}>
                    Logout
                  </Link>
                </li>
              </ul>
            </details>

            <button
              onClick={toggleNav}
              id='menu'
              className={`block md:hidden ${
                navbar ? "text-black" : ` ${dark ? "text-black" : "text-white"}`
              }`}
            >
              <AiOutlineMenu size={20} />
            </button>
          </ul>
        </div>
      </nav>
      {toggle && (
        <div
          onClick={stopPropagation}
          className='w-full h-full z-[40] bg-white fixed inset-0'
        >
          <div className='flex items-center justify-between p-4 border-b border-gray-100'>
            <img
              src='https://ik.imagekit.io/noah/paspotblue.png?updatedAt=1709412003987'
              alt='logo'
              className='w-20 h-auto'
            />
            <button onClick={toggleNav}>
              <GrFormClose size={25} />
            </button>
          </div>
          <ul className='menu menu-vertical px-4 z-[60] space-y-6 '>
            <Link
              reloadDocument
              to='/'
              className='flex text-lg font-medium items-center justify-between py-3 border-b border-neutral-content'
            >
              Home
              <BiChevronRight size={20} />
            </Link>

            <Link
              reloadDocument
              to='/discover'
              className='flex text-lg font-medium items-center justify-between py-3 border-b border-neutral-content'
            >
              Browse events
              <BiChevronRight size={20} />
            </Link>
            <Link
              reloadDocument
              to='/tickets'
              className='flex text-lg font-medium items-center justify-between py-3 border-b border-neutral-content'
            >
              Your tickets
              <BiChevronRight size={20} />
            </Link>

            <div
              onClick={toggleChild}
              className='flex text-lg font-medium items-center justify-between py-3 border-b border-neutral-content'
            >
              Work with us
              <BiChevronDown size={20} />
            </div>
            {showChild && (
              <div
                onClick={toggleChild}
                className='md:h-screen md:fixed z-[90] w-full'
              >
                <ul className='ease-in-out duration-700 menu menu-vertical w-full md:w-64 md:absolute md:top-20 md:right-80 md:shadow-lg md:border bg-white rounded-md'>
                  <li>
                    <Link reloadDocument to='/eventplanners'>
                      Event Planners
                    </Link>
                  </li>
                  <li>
                    <Link reloadDocument to='/Venues'>
                      Venues
                    </Link>
                  </li>
                  <li>
                    <Link reloadDocument to='/urgent2k'>
                      Urgent 2k
                    </Link>
                  </li>
                </ul>
              </div>
            )}

            <Link
              reloadDocument
              to='/help'
              className='flex text-lg font-medium items-center justify-between py-3 border-b border-neutral-content'
            >
              Help desk
              <BiChevronRight size={20} />
            </Link>
            <Link
              reloadDocument
              to='/appAccount'
              className='btn btn-primary normal-case text-white font-medium'
            >
              Settings
            </Link>
            <Link
              reloadDocument
              onClick={handleOnPressLogout}
              className='btn btn-primary normal-case text-white font-medium'
            >
              Logout
            </Link>
          </ul>
        </div>
      )}
      {toggleSearch && (
        <div
          onClick={stopPropagation}
          className='w-full h-full z-[40] bg-white fixed inset-0'
        >
          <div className='max-w-4xl mx-auto flex items-center justify-between border-b border-gray-100 p-4'>
            <div className='flex items-center gap-2 w-full px-4 bg-gray-100 rounded-full'>
              <BiSearch size={24} />
              <input
                type='text'
                placeholder='Search for event name'
                className='w-full border-0 bg-transparent py-3 focus:outline-none'
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <button
              className='btn btn-ghost normal-case font-medium btn-sm'
              onClick={toggleBar}
            >
              Close
            </button>
          </div>
          <div className='py-4 rounded-md mt-4 md:mt-16 bg-white max-w-4xl mx-auto space-y-4'>
            <div className='px-4'>
              <h1 className='font-semibold text-xl'>Popular</h1>
            </div>
            <Suspense>
              {filteredEvents ? (
                filteredEvents
                  .slice(0, 20)
                  .map((event) => <EventItem event={event} />)
              ) : (
                <span className='text-primary loading loading-spinner'></span>
              )}
            </Suspense>
          </div>
        </div>
      )}
      {showChild && (
        <div
          onClick={toggleChild}
          className='md:h-screen md:fixed z-[90] w-full'
        >
          <ul className='ease-in-out duration-700 menu menu-vertical w-full md:w-64 md:absolute md:top-0 md:right-20 md:shadow-lg md:border bg-white rounded-md'>
            <li>
              <Link reloadDocument to='/eventplanners'>
                Event Planners
              </Link>
            </li>
            <li>
              <Link reloadDocument to='/venues'>
                Venues
              </Link>
            </li>
            <li>
              <Link reloadDocument to='/urgent2k'>
                Urgent 2k
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Navbar3;
