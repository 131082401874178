import { Formik } from "formik";
import React from "react";

export default function AppForm({
  initialValues,
  onSubmit,
  validationSchema,
  children,
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <form
          className='flex flex-col gap-4 text-center w-full'
          onSubmit={handleSubmit}
        >
          {children}
        </form>
      )}
    </Formik>
  );
}
