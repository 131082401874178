import {
  RiCloseFill,
  RiCouponLine,
  RiDashboardLine,
  RiLogoutBoxLine,
  RiMenuLine,
  RiUser2Line,
  RiUserSmileLine,
} from "@remixicon/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../Modal";
import LoginForm from "../forms/loginform";
import SignupForm from "../forms/signupform";
import Logo from "../logoMark";
import auth from "../../api/auth";
import { FaRegUserCircle, FaUserCircle } from "react-icons/fa";

//
function Navbar1({ dark }) {
  const [navbar, setNavbar] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [account, setAccount] = useState(null);
  const [nav, setNav] = useState(0);
  const [showLoginForm, setShowLoginForm] = useState(false);

  useEffect(() => {
    const auth = window.localStorage.getItem("authType");
    if (auth === "planner") setNav(2);
    if (auth === "user") setNav(1);
  }, []);

  const toggleForm = () => {
    setShowForm(!showForm);
    console.log("clicked");
  };

  const handleLoginClick = () => {
    setShowForm(true);
    setShowLoginForm(true);
  };

  const handleSignupClick = () => {
    setShowForm(true);
    setShowLoginForm(false);
  };

  const toggleNav = () => {
    setToggle(!toggle);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleOnPressLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      window.localStorage.clear();
      window.location.reload();
    }
  };

  const mobileListStyle =
    "text-lg font-medium py-3 border-b border-neutral-100";
  const dropdownListStyle = "flex items-center gap-4 py-2";

  const getFirstChar = (str) => {
    return str && str.length > 0 ? str[0] : "";
  };

  const name = window.localStorage.getItem("name");

  // const username = getFirstChar(account?.name || "*");

  return (
    <header className='sticky bg-base-100 w-full h-fit inset-0 z-40 md:p-4 px-4 py-2 md:px-0 border-b border-foreground-50 mb-4 md:mb-8'>
      {/* DEFAULT NAVBAR */}
      <nav className='flex items-center justify-between max-w-7xl mx-auto'>
        <Link to='/'>
          <Logo regular className='w-24 h-auto md:w-28 md:h-auto' />
        </Link>
        <ul className='md:flex items-center gap-6 hidden'>
          <li>
            <Link reloadDocument to='/dashboard'>
              Dashboard
            </Link>
          </li>

          <li>
            <Link reloadDocument to='/liveevents'>
              Live events
            </Link>
          </li>

          <li>
            <Link reloadDocument to='/help'>
              Customer Support
            </Link>
          </li>

          {nav === 0 ? (
            <div className='flex items-center gap-2'>
              <button
                onClick={handleLoginClick}
                className='btn btn-outline font-medium border-[#404040]'
              >
                LOGIN
              </button>
              <button
                onClick={handleSignupClick}
                className='btn btn-primary font-medium text-white'
              >
                SIGN UP
              </button>
            </div>
          ) : (
            <div className='dropdown dropdown-end'>
              <div
                tabindex='0'
                role='button'
                className='btn btn-circle btn-sm w-10 h-10 btn-neutral text-white font-medium'
              >
                <p>
                  <FaUserCircle size={24} />
                </p>
              </div>

              <ul className='menu dropdown-content bg-base-100 rounded-md z-[1] w-60 border border-neutral-content p-4 shadow-md'>
                {/* FOR USERS */}

                {/* FOR PLANNERS */}
                {nav === 2 && (
                  <>
                    <Link
                      reloadDocument
                      to='/tickets'
                      className={dropdownListStyle}
                    >
                      <RiCouponLine size={18} />
                      Tickets
                    </Link>
                    <Link
                      reloadDocument
                      to='/coordinator'
                      className={dropdownListStyle}
                    >
                      <RiUser2Line size={18} />
                      Accounts
                    </Link>
                    <Link
                      reloadDocument
                      to='/account'
                      className={dropdownListStyle}
                    >
                      <RiUserSmileLine size={18} />
                      My Account
                    </Link>
                    <button
                      onClick={handleOnPressLogout}
                      className='flex text-error items-center gap-4 mt-4 py-2 border-t border-neutral-content'
                    >
                      <RiLogoutBoxLine size={18} />
                      Logout
                    </button>
                  </>
                )}
              </ul>
            </div>
          )}
        </ul>
        <div className='flex gap-2 items-center md:hidden'>
          <button
            onClick={toggleNav}
            id='menu'
            className='btn btn-ghost btn-circle'
          >
            <RiMenuLine size={24} />
          </button>
          {nav === 0 ? (
            ""
          ) : (
            <Link
              to='/account'
              className='btn btn-neutral btn-sm btn-circle text-white'
            >
              <p>
                <FaUserCircle size={24} />
              </p>
            </Link>
          )}
        </div>
      </nav>

      <Modal showTitle={false} state={showForm} toggle={toggleForm}>
        {showLoginForm ? (
          <LoginForm onClose={toggleForm} title='Login to paspot' />
        ) : (
          <SignupForm onClose={toggleForm} />
        )}
      </Modal>

      {toggle && (
        <div
          onClick={toggleNav}
          className='w-full h-full z-[10] bg-[#0000007e] fixed flex justify-end inset-0'
        >
          <div
            onClick={stopPropagation}
            className='w-80 h-full max-h-screen flex flex-col gap-4 justify-between py-4 bg-base-100'
          >
            <div className='flex flex-col gap-4'>
              <div className='flex items-center justify-between p-4'>
                <Logo regular className='w-24 h-auto md:w-32 md:h-auto' />

                <button
                  className='btn btn-sm btn-neutral text-white btn-circle'
                  onClick={toggleNav}
                >
                  <RiCloseFill size={25} />
                </button>
              </div>
              <ul className='menu menu-vertical px-4 z-[60] space-y-6 '>
                {/* <Link reloadDocument to='/about' className={mobileListStyle}>
                  About
                </Link> */}
                <Link
                      reloadDocument
                      to='/dashboard'
                      className={mobileListStyle}
                    >
                      Dashboard
                    </Link>
                <Link reloadDocument to='/liveevents' className={mobileListStyle}>
                  Live events
                </Link>

                <Link reloadDocument to='/help' className={mobileListStyle}>
                  Customer Support
                </Link>

                {/* FOR PLANNERS */}
                {nav === 2 && (
                  <>
                    
                    <Link
                      reloadDocument
                      to='/tickets'
                      className={mobileListStyle}
                    >
                    Tickets
                    </Link>
                    <Link
                      reloadDocument
                      to='/coordinator'
                      className={mobileListStyle}
                    >
                      Accounts
                    </Link>
                  </>
                )}
              </ul>
            </div>

            <div className='px-4'>
              {nav === 0 ? (
                <div className='flex flex-col items-center gap-2'>
                  <button
                    onClick={handleLoginClick}
                    className='btn btn-outline btn-block font-medium'
                  >
                    Login
                  </button>
                  <button
                    onClick={handleSignupClick}
                    className='btn btn-primary btn-block text-white font-medium'
                  >
                    Get Started
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleOnPressLogout}
                  className='btn btn-primary btn-block text-white font-medium'
                >
                  Logout
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

export default Navbar1;
