import Marquee from "react-fast-marquee";

export default function Banner() {
  return (
    <Marquee
      speed={100}
      autoFill={true}
      direction='right'
      className='bg-black border dark:border-foreground-100 border-y py-2 md:py-4'
    >
      <div className='flex items-center gap-4'>
        <span className='text-2xl banner md:text-4xl font-bold text-secondary uppercase'>
          Your pass to the spot
        </span>
        <span className='text-secondary uppercase md:text-4xl text-2xl mr-4'>
          *
        </span>
      </div>
    </Marquee>
  );
}
