import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import * as yup from "yup";
import accountApi from "../../api/auth";
import AppForm from "./AppForm";
import AppFormField from "./AppFormFied";
import { BiArrowBack } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import { FcGoogle } from "react-icons/fc";
import SelectTickets from "./SelectTickets";
import Signupform from "./signupform";
import Login from "./loginform";

const validationSchemaSignUp = yup.object().shape({
  email: yup
    .string()
    .email("Please provide a valid email address")
    .required("This field is required"),
  password: yup.string().required("This field is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("This field is required"),
});

export default function SignUpCustomer({
  fromBuyTicket,
  toggleMenu,
  setView,
  initialView,
  tickets,
  handleOnChange,
  finalPrice,
  buttonLoading,
  handleOnTicketFree,
  componentProps,
  resetFinalPrice,
}) {
  const [signUp, setSignUp] = useState(initialView === "signup");
  const [showTickets, setShowTickets] = useState(false);
  const isLoggedIn = !!window.localStorage.getItem("token");
  const handleBackClick = () => setView("notice");

  return (
    <div>
      {showTickets ? (
        <SelectTickets
          tickets={tickets}
          handleOnChange={handleOnChange}
          finalPrice={finalPrice}
          buttonLoading={buttonLoading}
          handleOnTicketFree={handleOnTicketFree}
          componentProps={componentProps}
          inner={!isLoggedIn}
          handleBackClick={handleBackClick}
          toggleMenu={toggleMenu}
          resetFinalPrice={resetFinalPrice}
        />
      ) : fromBuyTicket ? (
        signUp ? (
          <Signupform
            fromBuyTicket={fromBuyTicket}
            setSignUp={setSignUp}
            handleBackClick={handleBackClick}
            showAuthHeader={false}
            noAuthHeader
            onLoginSuccess={() => setShowTickets(true)}
          />
        ) : (
          <Login />
        )
      ) : (
        <Signupform
          fromBuyTicket={fromBuyTicket}
          setSignUp={setSignUp}
          handleBackClick={handleBackClick}
          showAuthHeader={false}
          noAuthHeader
          onLoginSuccess={() => setShowTickets(true)}
        />
      )}
    </div>
  );
}
