import React from "react";
import { AiOutlineRight } from "react-icons/ai";

const singleTicket = ({ image, eventName, ticketClass, handleOnClick }) => {
  return (
    <button
      className='flex py-4 items-center border-b border-black justify-between text-left w-full'
      onClick={handleOnClick}
    >
      <div className='flex items-center gap-4'>
        <img
          src={image}
          className='w-24 h-24 object-cover rounded-md'
          alt='ticket'
        />
        <div>
          <h1 className='font-semibold text-xl'>{eventName}</h1>
          <p>{ticketClass}</p>
        </div>
      </div>
      <AiOutlineRight />
    </button>
  );
};

export default singleTicket;
