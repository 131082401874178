import React from "react";

function Wrong() {
  return (
    <div className='flex px-4 flex-col gap-6 items-center justify-center h-screen text-white bg-[#ff0033]'>
      <h2 className='font-bold text-2xl'>Omo wahala wa o</h2>
      <img
        src='https://ik.imagekit.io/noah/giphy2.gif?updatedAt=1704591468413'
        alt='gif'
        className='md:w-96 w-full h-auto'
      />
      <div className='text-center'>
        <h2 className='px-4'>This ticket has already been used</h2>
      </div>
      <button
        onClick={() => window.location.reload()}
        className='btn btn-block bg-white'
      >
        Go back
      </button>
    </div>
  );
}

export default Wrong;
